import Decimal from 'decimal.js';
import accounting from 'accounting';

export function formatNumber(input: string|number|Decimal, dp: number) {
  return accounting.formatNumber(
    // Pre-truncate the input using Decimal
    new Decimal(input)
      .toDecimalPlaces(dp || 0)
      .toNumber(),
    // Number of decimal places
    dp,
    // Separators (group, decimal)
    '.', ',',
  );
}
